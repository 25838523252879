import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Graph from 'react-vis-network-graph'
import constants from '../helpers/constants'
import fetchGraphData from '../helpers/fetchGraphData'
import { handleScreenResize } from '../handlers/handleScreenResize'
import { updateNodes } from '../helpers/updateNodes'
import { updateEdges } from '../helpers/updateEdges'
import Navbar from './Navbar'
import { mergeGraphs } from '../helpers/mergeGraphs'
import focusOnNode from '../helpers/focusOnNode'
import TriviaGame from './TriviaGame'
import Modal from './Modal'
import { getNodePathFromRoot } from '../helpers/getNodePathFromRoot'
import { getNodeContent } from '../helpers/getNodeContent'
import { cutArray } from '../helpers/cutArray'

const DynamicGraph = (props) => {
  const networkRef = useRef()
  const { topic } = useParams()
  const [currentTopic] = useState(topic)
  const [screenWidth, setScreenWidth] = useState(512)
  const [screenHeight, setScreenHeight] = useState(512)
  const timeoutRefForGraphUpdate = useRef();
  const [physicsEnabled, setPhysicsEnabled] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [questionObject, setQuestionObject] = useState({});


  const graphStartState = {
    nodes: [{
      id: 1, label: currentTopic, class: "start"
    }],
    edges: []
  }
  const [graph, setGraph] = useState(graphStartState)

  useEffect(() => {
    updateNodes(graph, networkRef)
    updateEdges(graph, networkRef)
  }, [graph])

  useEffect(() => {
    return handleScreenResize(setScreenWidth, setScreenHeight);
  }, [])

  const updateNodeInGraph = (prevGraph, nodeId, newProperties) => {
    const updatedNodes = prevGraph.nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          ...newProperties,
        };
      }
      return node;
    });

    return {
      ...prevGraph,
      nodes: updatedNodes,
    };
  };

  const showModal = () => {
    return new Promise((resolve) => {
      setModalOpen(true);

      const handleClose = () => {
        setModalOpen(false);
        resolve();
      };

      document.addEventListener('closeModal', handleClose, { once: true });
    });
  };

  const handleNodeSelection = async (event) => {
    if (timeoutRefForGraphUpdate.current) {
      clearTimeout(timeoutRefForGraphUpdate.current);
    }

    const { nodes } = event;
    if (nodes.length > 0) {
      timeoutRefForGraphUpdate.current = setTimeout(async () => {
        setIsFetching(true);
        setPhysicsEnabled(true);

        var graphCopy = JSON.parse(JSON.stringify(graph))
        const selectedNode = getNodeContent(graphCopy, nodes[0])

        if (selectedNode.class === "start") {
          focusOnNode(2, networkRef)
        } else {
          focusOnNode(selectedNode.id, networkRef)
        }

        if (selectedNode.class === "recommendation"){
          var url = `https://www.google.com/search?q=` + encodeURIComponent(selectedNode.label.replace(/\n/g, ' '));
          window.open(url, '_blank');
          setIsFetching(false);
          return;
        }

        const pathFromRoot = getNodePathFromRoot(graphCopy, 1, selectedNode.id)
        const pathFromRootNodes = pathFromRoot.map((id) => getNodeContent(graphCopy, id))
        const context = pathFromRootNodes.map((node) => node.label)
        
        const edgeToSelectedNode = graphCopy.edges.find((edge) => {
          return edge.to === selectedNode.id
        })

        var neighboors
        if (edgeToSelectedNode){
          const edgesFromSameParent = graphCopy.edges.filter((edge) => {
            return edge.from === edgeToSelectedNode.from
          })

          const justIds = edgesFromSameParent.map((edge)=> edge.to)
          const neighboorNodes = graphCopy.nodes.filter((node)=>{
            return justIds.includes(node.id)
          })

          neighboors = neighboorNodes.map((node) => node.label)
        }
        
        graphCopy = updateNodeInGraph(graphCopy, selectedNode.id, selectedNode)
        const body = {
          input: currentTopic,
          class: selectedNode.class,
          parentId: selectedNode.id,
          offset: graphCopy.nodes.length,
          context: context,
          neighboors: neighboors
        }

        var newGraphData = await fetchGraphData(body)
        setIsFetching(false);

        if (newGraphData.question !== undefined) {
          setQuestionObject(newGraphData);
          await showModal();
        }

        if (selectedNode.class === "questionCard") {
          setGraph(graphCopy)
        } else {
          const mergedGraph = mergeGraphs(graphCopy, newGraphData)
          setGraph(mergedGraph)
        }

        setTimeout(() => {
          setPhysicsEnabled(false)
          if (selectedNode.class === "start") {
            focusOnNode(2, networkRef)
          } else {
            focusOnNode(selectedNode.id, networkRef)
          }
        }, 1250)

      }, 100);
    }
  };

  useEffect(() => {
    handleNodeSelection({ nodes: [1] })
  }, [])

  const events = {
    select: (event) => handleNodeSelection(event),
    hoverNode: (event) => {
      document.body.style.cursor = 'pointer';
    },
    blurNode: (event) => {
      document.body.style.cursor = 'default';
    },
  };

  const togglePhysics = () => {
    setPhysicsEnabled(!physicsEnabled);
  };

  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setOptions({
        physics: { enabled: physicsEnabled },
      });
    }
  }, [physicsEnabled])

  return (
    <div>
      <Navbar
        handlePlayClick={togglePhysics}
        playing={physicsEnabled}
        isFetching={isFetching}
      />
      <Graph
        getNetwork={(network) => { networkRef.current = network; }}
        graph={graphStartState}
        options={{ ...constants.graph.options, ...{ height: `${screenHeight}px`, width: `${screenWidth}px` } }}
        events={events}
      />
      {isModalOpen && (
        <Modal>
          <TriviaGame
            questionObject={questionObject}
            onClose={() => {
              document.dispatchEvent(new CustomEvent('closeModal'));
            }} />
        </Modal>
      )}
    </div>
  );
};

export default DynamicGraph