import constants from "../helpers/constants";

export default async (body) => {
    const response = await fetch(
        `${constants.fetchGraphUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error(response);
    }
    
    return await response.json();
}