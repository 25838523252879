import { IoPlay } from "react-icons/io5";
import { IoIosPause } from "react-icons/io";
import { BiHome } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";

export default ({
    handlePlayClick,
    playing,
    isFetching
}) => {
    return <div className='navbar'>
        <button onClick={() => window.location.href = '/'} className='navbar-item icon'>
            {<BiHome />}
        </button>
        <button onClick={handlePlayClick} className='navbar-item icon'>
            {!playing ? <IoPlay /> : <IoIosPause />}
        </button>
        {isFetching ? <button onClick={handlePlayClick} className='navbar-item icon blink_me rotating disabled'>
            <LuLoader/>
        </button> : null}

    </div>;
}