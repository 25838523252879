import ReactDOM from 'react-dom'
import '../../css/Modal.css'

export default function Modal({ children }) {
    const onClose = () => {
        document.dispatchEvent(new CustomEvent('closeModal'));
    }
    
    const handleBackdropClick = (e) => {
        if (e.target.classList.contains('modal-backdrop')) {
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <div className="modal-backdrop" onClick={handleBackdropClick}>
            <div className="modal-content">
                {children}
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}