export const getNodePathFromRoot = (graph, rootNodeId, targetNodeId) => {
  const { edges } = graph;
  const queue = [[rootNodeId]];
  const visited = new Set();

  while (queue.length > 0) {
    const path = queue.shift();
    const nodeId = path[path.length - 1];

    if (nodeId === targetNodeId) {
      return path;
    }

    if (!visited.has(nodeId)) {
      visited.add(nodeId);

      const neighbors = edges
        .filter(edge => edge.from === nodeId)
        .map(edge => edge.to);

      neighbors.forEach(neighbor => {
        const newPath = [...path, neighbor];
        queue.push(newPath);
      });
    }
  }

  console.warn('No path found');
  return null;
};
