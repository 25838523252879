import React, { useState } from 'react';
import '../../css/TriviaGame.css';

function TriviaGame({ questionObject, onClose }) {
  const [score, setScore] = useState(0);

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setScore((prev) => prev + 1);
    }

    onClose()
  };

  if (JSON.stringify(questionObject) === "{}") {
    return
  }

  return (
    <div className="TriviaGame-container">
      <div className="question-slide">
        <h2>{questionObject.question}</h2>
        <div className="answers-container">
          {[...questionObject.wrongAlternatives, questionObject.rightAnswer]
            .sort(() => Math.random() - 0.5) // Shuffle answers
            .map((answer, i) => (
              <button
                key={i}
                onClick={() =>
                  handleAnswer(answer === questionObject.rightAnswer)
                }
                className="answer-button"
              >
                {answer}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TriviaGame;
