const constants = {
    fetchGraphUrl: "https://marcosogs.dev/api/nodecharm/malaquias",
    fetchContentUrl: "https://marcosogs.dev/api/nodecharm/waldecir",
    splitStringEveryNChars: 40,
    baseIntervalForWordHighlight: 60,
    graph: {
        options: {
            nodes: {
                color: {
                    border: 'transparent',
                    background: '#666666',
                    hover: {
                        border: 'transparent',
                        background: '#aaaaaa',
                    },
                    highlight: {
                        border: 'transparent',
                        background: '#666666',
                    },
                },
                shape: 'dot',
                font: {
                    size: 22,
                    color: '#ffffff77',
                    face: 'Arial',
                    multi: true
                },
                scaling: {
                    min: 20,
                    max: 150,
                },
            },
            layout: {
                hierarchical: false
            },
            edges: {
                color: "#9b9b9b33",
                arrows: {
                    to: { enabled: true, scaleFactor: 2 }
                },
                smooth: {
                    type: 'continuous',
                    forceDirection: 'none',
                    roundness: 0.5,
                }
            },
            height: "100vh",
            interaction: {
                tooltipDelay: 99999999999999999999,
                hover: true,
                multiselect: true
            },
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -1000,
                    centralGravity: 0.01,
                    springLength: 200,
                    springConstant: 0.08
                },
                stabilization: {
                    enabled: true,
                    iterations: 30,
                    updateInterval: 25,
                },
            },
            groups: {
                question: {
                    color: { background: '#1F77B4' }
                },
                answear: {
                    color: { background: '#FF7F0E' }
                },
                recommendation: {
                    color: { background: '#2CA02C' }
                }
            }
        },

        nodes: {
            style: {
                normal: {
                    font: {
                        color: '#ffffff77',
                        strokeWidth: 0
                    }
                },
                anthesis: {
                    color: '#ff4e50'
                },
                author: {
                    color: '#ac4eff'
                },
                selected: {
                    font: {
                        color: '#ffffff',
                        strokeWidth: 1,
                        strokeColor: "#000000"
                    },
                }
            }
        },
        edges: {
            style: {
                normal: {
                    color: '#ffffff2f'
                },
                selected: {
                    color: "#87EC65"
                },
                from: {
                    color: '#434343'
                },
                to: {
                    color: '#00BFFF7f'
                }
            }
        }
    }
}

export default constants